<template>
  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="申报" name="1" :cache="false">
      <batchSendGsSb ref="batchSendGsSb" :activeName = "activeName"></batchSendGsSb>
    </qzf-tabs-item>
    <qzf-tabs-item label="扣款" name="2" :cache="false">
      <batchSendGsKk></batchSendGsKk>
    </qzf-tabs-item>
    <qzf-tabs-item label="作废" name="3" :cache="false">
      <batchSendGsZf></batchSendGsZf>
    </qzf-tabs-item>
  </qzf-tabs>
  <!-- <div class="app-container">
    <initTable ref="initTable" :activeName = "activeName"></initTable>
  </div> -->
</template>

<script>
import batchSendGsSb from "./components/batchSendGsSb"
import batchSendGsKk from "./components/batchSendGsKk"
import batchSendGsZf from "./components/batchSendGsZf"
export default {
  name:'kjInit',
  components:{
    batchSendGsSb,
    batchSendGsKk,
    batchSendGsZf
  },
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init'
      },
    }
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>

</style>