<template>
  <div class="app-container">
   <!-- todo -->
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="我的申报" name="我的申报"></el-tab-pane>
      <el-tab-pane label="学生申报" name="学生申报" v-if="this.userRoleName != '学生'"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        
        <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList" />
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:700px" class="styleForm">
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
              <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
            </el-form-item>

            <el-form-item label="选择班级 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生' && activeName != '我的申报'">   
              <selectClass v-model:deptId="listQuery.deptId" @success="getUserList" style="margin-left:5px"></selectClass>
            </el-form-item>
            <el-form-item label="选择人员：" :label-width="formLabelWidth"  v-if="this.userRoleName != '学生' && activeName != '我的申报'">
              <selectuser ref="selectuser" code="bookkeeping" eduCode="" v-model:userId="listQuery.userIds" @success="getUser"></selectuser>
            </el-form-item>

            <el-form-item label="结账状态 ：" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.jzStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结账</el-radio>
                <el-radio :label="2">未结账</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- <el-form-item label="任务状态：" :label-width="formLabelWidth">
              <selectTaskStatus v-model:taskStatus="listQuery.taskStatus"></selectTaskStatus>
            </el-form-item> -->

            <el-form-item label="申报状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.allStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="税款:" :label-width="formLabelWidth">
              <el-radio v-model="listQuery.taxStatus" :label="0">全部</el-radio>
              <el-radio v-model="listQuery.taxStatus" :label="1">金额不为0</el-radio>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <!-- <el-button size="small" type="success" @click="batchCopySalary()" v-if="activeName == '学生申报'"  :loading="loading">
          <el-icon><List /></el-icon><span  > 批量复制往期薪酬</span>
        </el-button> -->
        <el-button size="small" type="primary" @click="declaration()"   >
          <el-icon><List /></el-icon><span  > 批量申报</span>
        </el-button>
      </div>
    </div>
    <div class=" loudou">
      <el-table stripe :data="list" :height="contentStyleObj"  border @selection-change="handleSelectionChange" @filter-change="tableFilterChange" >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" width="60" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="320" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.comId)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报税种" width="280"
        :filters="this.categories">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="应补（退）税额" min-width="120">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="取数" min-width="100">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.gsMainStatus == '1'">已取数</p>
              <p v-if="item.gsMainStatus == '2'">未取数</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="left" prop="districtName" label="申报结果" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div>
                <span v-if="item.image" class="demo-image__preview" >
                  <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="item.bussinessStatus == 3 ||item.bussinessStatus == 4 ||item.bussinessStatus == 5 || item.bussinessStatus == 6|| item.bussinessStatus == 12" @click="open(item, index,scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if=" item.bussinessStatus == 8 " @click="open(item, index,scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="item.bussinessStatus == 10 ||item.bussinessStatus == 14 ||item.bussinessStatus == 13 || item.bussinessStatus == 20 " @click="open(item, index,scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#39b0d2;font-size:16px;" v-if="(item.bussinessStatus == 11 || item.bussinessStatus == 2 || item.bussinessStatus == 7)" @click="open(item, index,scope.$index)"></i>
                </span>
                <div class="item_icon">
                  <p>
                    <i v-if="!item.image" :class="$setUpStatusGsIconSb(item.taskStatus,item.bussinessStatus)"></i>
                    <el-tooltip v-if="scope.row.bussinessLog" class="item" effect="dark" :content="item.businessLog?item.businessLog:'' + item.errLog?item.errLog:''" placement="top-start">
                      <el-button size="small" :class="$setUpStatusGsWordSb(item.taskStatus,item.bussinessStatus)" style="border: 0;" @click="item.image?open(item, index,scope.$index):''">{{$setUpStatusGsSb(item.taskStatus,item.bussinessStatus)}}</el-button>
                    </el-tooltip>
                    <span v-else><el-button size="small" :class="$setUpStatusGsWordSb(item.taskStatus,item.bussinessStatus)" style="border: 0;" @click="item.image?open(item, index,scope.$index):''">{{$setUpStatusGsSb(item.taskStatus,item.bussinessStatus)}}</el-button></span>
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-button @click="handleUpdate(item)" size="small" type="text">申报</el-button>
              <el-button @click="declareDetail(scope.row,item)" size="small" type="text">申报记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    </el-tabs>
  </div>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    width="90%"
    top= "2vh">
    <span style=""><img :src="imgUrl" style="width:100%" ></span>
  </el-dialog>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <declareDetails ref="declareDetails"></declareDetails>
</template>

<script>
import { companySendTaxList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"

import { oneClickReplicationArr } from "@/api/salary"

import sbImgUrl from "./sbImgUrl"
import selectTaskStatus from "./select/selectTaskSatatus.vue"
export default {
  name: "batchSendSz",
  components:{
    selectTaskStatus,
    selecttaxtype,
    declareDetails,
    sbImgUrl
  },
  data(){
    return {
      activeName:'我的申报',
      formLabelWidth:'100px',
      listQuery: {
        page: 1,
        limit: 20,
        jzStatus: 0,
        // status:'2',
        taskName:'gs-sb-',
        name: '',
        period: currentAccountPeriod(),
        businessStatus: [],
        taskStatus: [],
        allStatus: [],
        accountingType: 2,
        taxNames:[],
        from: '个税',
        taxStatus: 0,
        userIds:[this.$store.getters['user/user'].userId]

        // payment1: 1,
      },
      total:0,
      list:[],
      dialogVisible: false,
      gsMainIds: undefined,
      pageStatus: true,
      srcList: ['https://file.listensoft.net/mnt/personaltax/202205_0_91370211MA3NNLN029.png'],
      imgUrl: '',
      options: [
        {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-2",
          label: '申报处理中',
        },
        {
          value: "task-4",
          label: '失败',
        },
        {
          value: "business-3",
          label: '申报超时失败',
        },
        {
          value: "business-4",
          label: '超时失败',
        },
        {
          value: "business-5",
          label: '申报异常',
        },
        {
          value: "business-6",
          label: '申报失败',
        },{
          value: "business-7",
          label: '申报成功，待缴款',
        },{
          value: "business-8",
          label: '排队中',
        },{
          value: "business-10",
          label: '申报成功，无需缴款',
        },{
          value: "business-11",
          label: '申报扣款中',
        },{
          value: "business-12",
          label: '扣款异常',
        },{
          value: "business-13",
          label: '申报成功，扣款成功',
        },{
          value: "business-14",
          label: '已申报过',
        },{
          value: "business-20",
          label: '申报成功',
        },
      ],
      categories: [
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
      ],
      selects:[],
      loading:false,
      userRoleName:this.$store.getters['user/user'].userRoleName
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(300)
    this.initBus()
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  methods:{
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if(item.taskStatus == 1 || item.taskStatus == 2 ) {
              status = true
            }
          })
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    open(row, index, bigIndex) {
      let index2 = 0
      let imageAll = []
      let i3 = 0
      this.list.map((v,i) => {
        v.items.map((res,i2)=>{
          index2++
          let url = {
            name: v.name,
            url: ""
          }
          if(i==bigIndex && i2 == index){
            i3 = index2 - 1
          }
          url.url = res.image ? 'https://file.listensoft.net/' + res.image + "?" + Math.random(10): ""
          imageAll.push(url)
        })
      })
      console.log(imageAll);
      this.$refs.sbImgUrl.dialog(imageAll, i3)
    },
    getList(){
      companySendTaxList(this.listQuery).then(res=>{
        this.list = res.data.data.list?res.data.data.list:[]
        this.total = res.data.data.total
      })
    },
    magnify(row) {
      this.srcList = ['https://file.listensoft.net/' + row]
    },
    handleClick(tab) {
      this.listQuery.userIds = []
      if(tab.props.name == "我的申报") {
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
        this.getList()
      }if(tab.props.name == "学生申报") {
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      this.selects = []
      let comIds = []
      row.map(info=>{
        comIds.push(info.comId)
        info.items.map(item=>{
          this.gsMainIds.push(item.gsMainId)
        })
      })
      this.selects = {
        period:this.listQuery.period,
        comIds:comIds
      }
    },
    // 申报详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    //批量申报
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: 'tax_sb',
        gsMainIds: this.gsMainIds
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要申报吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax_sb',
          gsMainIds: [e.gsMainId]
        }
        sendTask(param).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    batchCopySalary(){
      if(!this.selects.comIds || this.selects.comIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.loading = true
      oneClickReplicationArr(this.selects).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.getList()
        }
      })
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUser(e){
      this.listQuery.userIds = []
      if(this.listQuery.userIds.length == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 5px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>